var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Transition", { attrs: { name: "fade" } }, [
    _vm.creatingMeet
      ? _c("div", { staticClass: "loading-meet-modal" }, [
          _c("div", { staticClass: "loading-items" }, [
            _c("div", { staticClass: "title" }, [
              _c("span", { staticClass: "has-text-white" }, [
                _vm._v("Creating")
              ]),
              _c("span", { staticClass: "has-text-primary ml-2" }, [
                _vm._v("Meet")
              ])
            ]),
            _c("div", { staticClass: "MSpinner" }, [_c("div"), _c("div")])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <Transition name="fade">
    <div class="loading-meet-modal" v-if="creatingMeet">
      <div class="loading-items">
        <div class="title">
          <span class="has-text-white">Creating</span>
          <span class="has-text-primary ml-2">Meet</span>
        </div>

        <div class="MSpinner">
          <div />
          <div />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
export default {
  name: 'LoadingMeetModal',
  props: { creatingMeet: Boolean }
}
</script>

<style lang="scss" scoped>
.loading-items {
  @include overlay();
  @include flex();
  z-index: 10;
  background: rgba(78, 78, 78, 0.486);
  transition: all 0.5s;
  @include flex();
  flex-direction: column;
  font-size: 50px;
  .title {
    margin-bottom: 20px;
    text-shadow: rgb(34, 34, 34) 0 0 15px;
    span {
      font-size: 30px;
    }
  }
}
</style>

var getCalendarBody = function (dateTime, summary, description, attendees) {
    return {
        summary: summary,
        description: description,
        start: {
            dateTime: dateTime
        },
        end: {
            dateTime: dateTime
        },
        attendees: attendees
    };
};
export default getCalendarBody;
